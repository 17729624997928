/* eslint-disable */
import React from 'react'
import Helmet from '../components/Helmet'

import { graphql } from 'gatsby'

import coupleHiking from '../images/heroes/couple-hiking.jpg'
import coupleHikingMobile from '../images/heroes/couple-hiking-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../images/brand-logos/aetna.svg'
import cigna from '../images/brand-logos/cigna.svg'
import regence from '../images/brand-logos/regence.svg'
import united from '../images/brand-logos/united-healthcare.svg'
import wellcare from '../images/brand-logos/wellcare.svg'
import mutual from '../images/brand-logos/mutual-omaha.svg'

import checkmarkPrimaryLarge from '../images/icons/checkmark-primary-large.svg'
import checkmarkStarPrimary from '../images/icons/check-star-primary.svg'
import xIconDark from '../images/icons/x-dark.svg'

import nursesHat from '../images/illustrations/nurses-hat.svg'
import doctor from '../images/illustrations/doctor.svg'
import prescriptionDrugs from '../images/illustrations/prescription-drugs.svg'
import glasses from '../images/illustrations/glasses.svg'
import dollar from '../images/illustrations/dollar.svg'

import ResponsiveGrid from '../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import headerData from '../data/headerData.js'
import isCallCenterOpen from '../../../../utils/isOpen'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-dsnplp']
  const disclaimerList = brandyList
    .map((disclaimerId) =>
      data.allBrandyDisclaimer.edges.find(
        ({ node }) => node.brandy_id === disclaimerId
      )
    )
    .filter((node) => node)

  const footerDisclaimers = disclaimerList.map(({ node }) => {
    return {
      brandy: {
        text: node.text,
        symbol: node.symbol,
        key: node.brandy_id,
      },
    }
  })

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title: 'Find & Compare Medicare Plans | HealthcarePlans.com',
          description:
            'Discover and compare Medicare plans suitable for your needs. Find a plan online or connect with an expert today to explore your options.',
          canonical: 'https://www.healthcareplans.com/plans',
          robots: 'follow,index',
        },
        path: '/plans',
        promoCode: '170033',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={coupleHiking}
                  alt="an elderly couple go for a hike on a trail"
                />
              }
              mobileImage={
                <img
                  src={coupleHikingMobile}
                  alt="an elderly couple go for a hike on a trail"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Find the right medicare plan for your needs{' '}
                  </Typography>

                  <Typography variant="body">
                    Learn everything you need to know to make an informed
                    decision about Medicare plans. Use our Medicare plan finder
                    to compare available Medicare Advantage plans, Medicare Part
                    D, and Special Needs Plans in your area.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className=""
                      requestId={requestId}
                    >
                      Call Now {rotatedNumber}
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="Find a Medicare D-SNP Plan"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos bg-lightgray"
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">Types of Medicare plans</Typography>
            </>
          }
          alignMainContent="center"
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns mobileCarousel>
              <Column backgroundColor="light" className="card-padding">
                <div className="content-container">
                  <StaticImage
                    src="../images/illustrations/stethascope.svg"
                    alt="stethascope icon"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="h5">
                    Medicare Advantage (Part C)
                  </Typography>
                  <Typography variant="body">
                    Medicare Part C, also known as Medicare Advantage, is
                    provided by private insurers as an alternative to Original
                    Medicare (Parts A & B). Advantage plans often offer
                    additional benefits beyond hospital and medical insurance,
                    including but not limited to vision, dental, and
                    prescription drug benefits.
                  </Typography>
                </div>
                <div className="cta-container">
                  <LinkButton
                    variant="feature"
                    to="/plans/medicare-advantage"
                    color="primary"
                    className=""
                  >
                    Learn More about Part C
                  </LinkButton>
                </div>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <div className="content-container">
                  <StaticImage
                    src="../images/illustrations/medicine.svg"
                    alt="medicine icon"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="h5">
                    Medicare Prescription Drug Coverage (Part D)
                  </Typography>
                  <Typography variant="body">
                    Medicare Part D prescription drug coverage is offered by
                    private insurance carriers. Part D plans work in concert
                    with Original Medicare (Parts A & B) to help with the cost
                    of prescription medications.
                  </Typography>
                </div>
                <div className="cta-container">
                  <LinkButton
                    variant="feature"
                    to="/plans/medicare-part-d"
                    color="primary"
                    className=""
                  >
                    Learn More about Part D
                  </LinkButton>
                </div>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <div className="content-container">
                  <StaticImage
                    src="../images/illustrations/medicare-cards.svg"
                    alt="medicare-cards icon"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="h5">
                    Medicare Dual Eligible Special Needs Plan (DSNP)
                  </Typography>
                  <Typography variant="body">
                    A Medicare Dual Eligible Special Needs Plan, often
                    abbreviated DSNP or D-SNP, provides additional benefits for
                    eligible users who qualify for both Medicare and Medicaid
                    benefits. DNSP premiums run as low as $0 monthly.
                  </Typography>
                </div>
                <div className="cta-container">
                  <LinkButton
                    variant="feature"
                    to="/plans/snp/dsnp"
                    color="primary"
                    className=""
                  >
                    Learn More about DSNP
                  </LinkButton>
                </div>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <div className="content-container">
                  <StaticImage
                    src="../images/illustrations/dollars.svg"
                    alt="dollars icon"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="h5">
                    Supplemental Medicare (Medigap)
                  </Typography>
                  <Typography variant="body">
                    Medigap or Medicare supplement insurance helps fill the gaps
                    in Medicare Part A & B (Original Medicare) coverage. Medigap
                    plan benefits may include coverage for out-of-pocket costs
                    such as copayments and the ability to visit out-of-network
                    doctors and specialists.
                  </Typography>
                </div>
                <div className="cta-container">
                  <LinkButton
                    variant="feature"
                    to="/plans/supplemental-medicare"
                    color="primary"
                    className=""
                  >
                    Learn More about Medigap
                  </LinkButton>
                </div>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent backgroundColor="light">
          <div className="comparison-table bg-white">
            <div className="table-header">
              <Typography variant="h3">Medicare plan comparison</Typography>
            </div>
            <table className="table-main">
              <thead>
                <td></td>
                <td>
                  <span className="show-on-desktop">
                    Medicare Advantage Plans (Part C)
                  </span>
                  <span className="show-on-mobile">Part C</span>
                </td>
                <td>
                  <span className="show-on-desktop">
                    Original Medicare (Part A and B)
                  </span>
                  <span className="show-on-mobile">Part A & B</span>
                </td>
                <td>
                  <span className="show-on-desktop">
                    Prescription Drug Plans (Part D)
                  </span>
                  <span className="show-on-mobile">Part D</span>
                </td>
                <td>
                  <span className="show-on-desktop">
                    Supplemental Medicare (Medigap)
                  </span>
                  <span className="show-on-mobile">DSNP</span>
                </td>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src={nursesHat}
                      alt="illustration of a nurse's hat"
                      className="show-on-desktop"
                    />
                    <div>
                      <strong>Hospital coverage</strong>
                      <br />
                      (hospital expenses, hospice care, skilled nursing)
                    </div>
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={doctor}
                      alt="illustration of a doctor"
                      className="show-on-desktop"
                    />
                    <div>
                      <strong>Doctor and outpatient coverage</strong>
                      <br />
                      (doctor visits, outpatient care, preventative services)
                    </div>
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={prescriptionDrugs}
                      alt="illustration of prescription drugs"
                      className="show-on-desktop"
                    />
                    <div>
                      <strong>Prescription drug coverage</strong>
                      <br />
                      (prescription drugs from multiple tiers)
                    </div>
                  </td>
                  <td>
                    <img src={checkmarkStarPrimary} alt="checkmark icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={glasses}
                      alt="illustration of a pair of glasses"
                      className="show-on-desktop"
                    />
                    <div>
                      <strong>Dental or vision coverage</strong>
                      <br />
                      (dental exams, eye exams)
                    </div>
                  </td>
                  <td>
                    <img src={checkmarkStarPrimary} alt="checkmark icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={dollar}
                      alt="illustration of a dollar bill"
                      className="show-on-desktop"
                    />
                    <div>
                      <strong>Annual out-of-pocket maximum</strong>
                      <br />
                      (never pay more than a pre-determined annual amount)
                    </div>
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                  <td>
                    <img src={xIconDark} alt="x icon" />
                  </td>
                  <td>
                    <img src={checkmarkPrimaryLarge} alt="checkmark icon" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Typography variant="body" className="table-after">
            *Available on some Medicare Advantage plans
          </Typography>
        </VariableContent>

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Why HealthcarePlans.com</Typography>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../images/illustrations/heart-pieces.svg"
                  alt="heart-pieces icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="h5">
                  More than a decade of experience as an insurance broker
                </Typography>
                <Typography variant="body">
                  As of March 2023, there are more than 65 million Medicare
                  beneficiaries in the United States. Over 100,000 of those
                  received help from Healthcareplans.com and Clearlink Insurance
                  Agency.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../images/illustrations/choices.svg"
                  alt="choices icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="h5">Top insurance providers</Typography>
                <Typography variant="body">
                  As a private insurance agency, we are not beholden to a single
                  provider. We work with some of the leading insurance carriers
                  in the nation, including Cigna, Aetna, and Regence, to connect
                  you with the plan that best fits your needs and budget.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../images/illustrations/laptop.svg"
                  alt="laptop icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="h5">Online plan comparison</Typography>
                <Typography variant="body">
                  With our Medicare plan finder tool, you can get unbiased
                  results for Medicare plans available in your area and choose
                  based on what fits your need, not just what a single carrier
                  offers.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../images/illustrations/cell-phone.svg"
                  alt="cell-phone icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="h5">
                  U.S.-based and U.S.-licensed agents
                </Typography>
                <Typography variant="body">
                  If you need help over the phone, our U.S.-based, U.S.-licensed
                  agents are available to offer unbiased advice to help you find
                  the plan you need from one of our leading insurance partners.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <a
          href="https://www.trustpilot.com/review/clearlinkinsurance.com"
          target="_blank"
        >
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <span className="show-on-desktop margin-bottom-sm">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-desktop.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
                <span className="show-on-mobile margin-bottom-sm">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-mobile.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
              </>
            }
          />
        </a>

        <VariableContent
          backgroundColor="secondary"
          mainContent={
            <>
              <Typography variant="h2"> Find a Medicare plan today</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column backgroundColor="white" className="card-padding">
                <Typography variant="h5">Find a plan online</Typography>
                <Typography variant="body">
                  Use our Medicare plan finder tool to compare plans in your
                  location—and enroll online during a qualifying enrollment
                  period.
                </Typography>
                <div className="avail-form margin-left-0">
                  <AvailabilityForm
                    placeholderText="Zip Code"
                    buttonText="Find a plan"
                    buttonColor="primary"
                    butttonBackgroundColor="black"
                  />
                </div>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <Typography variant="h5">Give us a call</Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className=""
                  requestId={requestId}
                >
                  Call Now {rotatedNumber} | TTY 711
                </LinkButton>
                <Typography variant="body">
                  Our licensed agents are available Monday through Friday from
                  8:30 am to 5:00 pm MT.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Dual Eligible Special Needs Plans
            </Typography>
          }
          className="faq-section-padding"
          style={{ color: '#1B0B54' }}
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title: 'What are the four parts of Medicare?',
                  content: (
                    <>
                      <Typography>
                        Medicare coverage is generally divided into four parts.
                        The first two Parts, A & B, comprise what is also
                        referred to as Original Medicare, provided by the
                        federal government to eligible recipients over the age
                        of 65. Parts C & D act in place of, or in addition to,
                        Original Medicare, and coverage comes from private
                        insurance carriers rather than publicly available
                        governmental benefits.
                      </Typography>
                      <List>
                        <ListItem>
                          Medicare Part A provides inpatient/hospital coverage.
                        </ListItem>
                        <ListItem>
                          Medicare Part B provides outpatient/medical coverage.
                        </ListItem>
                        <ListItem>
                          Medicare Part C is the private alternative to A & B,
                          usually providing additional benefits as well as
                          hospital and medical coverage.
                        </ListItem>
                        <ListItem>
                          Medicare Part D provides prescription drug coverage.
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'What is original Medicare?',
                  content: (
                    <>
                      <Typography>
                        Original Medicare is another way to refer to Medicare
                        Parts A & B. These benefits are provided to
                        beneficiaries over the age of 65 by the federal
                        government.
                      </Typography>
                      <List>
                        <ListItem>
                          Medicare Part A provides inpatient/hospital coverage.
                        </ListItem>
                        <ListItem>
                          Medicare Part B provides outpatient/medical coverage.
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'Who is eligible for Medicare?',
                  content: (
                    <>
                      <Typography>
                        Medicare is generally available for anyone in the United
                        States over the age of 65. Some conditions may allow
                        people to receive benefits before the age of 65:
                      </Typography>
                      <List>
                        <ListItem>Some major disabilities</ListItem>
                        <ListItem>
                          End-stage renal disease (permanent kidney failure
                          requiring dialysis or transplant)
                        </ListItem>
                        <ListItem>
                          Amyotrophic lateral sclerosis (ALS, also known as Lou
                          Gehrig’s disease)
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'How do I enroll in Medicare?',
                  content: (
                    <>
                      <Typography>
                        To enroll in Medicare, you must be in one of the
                        qualified Medicare Enrollment Periods. The first of
                        these is your Initial Enrollment Period, which begins
                        three months before the month of your 65th birthday,
                        continues through your birthday month, and lasts for
                        three months after. If you are choosing federal
                        government coverage, enroll in Original Medicare online
                        or by phone at 1-800-772-1213 during this period to
                        prevent later premium penalties.
                      </Typography>
                      <Typography>
                        To enroll in private coverage such as Part C or
                        prescription drug coverage, you can use our Medicare
                        plan comparison tool to find the plan(s) that work best
                        for you and enroll online or over the phone during a
                        qualifying enrollment period:
                      </Typography>
                      <List>
                        <ListItem>
                          Annual Enrollment Period - October 15-December 7 each
                          year, available to anyone Medicare-eligible
                        </ListItem>
                        <ListItem>
                          Medicare Advantage Open Enrollment Period - January
                          1-March 31 each year, available to anyone already
                          enrolled in a Medicare Advantage plan
                        </ListItem>
                        <ListItem>
                          General Enrollment Period - January 1-March 31,
                          available for those who missed their Initial
                          Enrollment Period window to enroll in Medicare Parts A
                          & B.
                        </ListItem>
                        <ListItem>
                          Special Enrollment Periods - may occur throughout the
                          year, triggered by qualifying events
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'When should I enroll in Medicare?',
                  content: (
                    <>
                      <Typography>
                        Enroll in Medicare coverage during a qualified{' '}
                        <a href="/resources/medicare-enrollment-101/">
                          Medicare Enrollment Period
                        </a>
                        :
                      </Typography>
                      <List>
                        <ListItem>
                          Initial Enrollment Period - beginning 3 months before
                          your 65th birthday, continuing through your birthday
                          month and three months after
                        </ListItem>
                        <ListItem>
                          <a href="/resources/when-is-medicare-advantage-annual-enrollment/">
                            Annual Enrollment Period
                          </a>{' '}
                          - October 15-December 7 each year, available to anyone
                          Medicare-eligible
                        </ListItem>
                        <ListItem>
                          <a href="/resources/gep-oep-enrollment-periods/">
                            Medicare Advantage Open Enrollment Period
                          </a>{' '}
                          - January 1-March 31 each year, available to anyone
                          already enrolled in a Medicare Advantage plan
                        </ListItem>
                        <ListItem>
                          <a href="/resources/gep-oep-enrollment-periods/">
                            General Enrollment Period
                          </a>{' '}
                          - January 1-March 31, available for those who missed
                          their Initial Enrollment Period window to enroll in
                          Medicare Parts A & B
                        </ListItem>
                        <ListItem>
                          Special Enrollment Periods - may occur throughout the
                          year, triggered by qualifying events
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'How do I choose a Medicare plan?',
                  content: (
                    <>
                      <Typography>
                        To choose a Medicare plan, consider your current health
                        and prescription drug needs. If you have a primary care
                        physician or specialist you regularly see and prefer,
                        you can search available Medicare Advantage plans by
                        doctor to find one that allows you to keep seeing your
                        preferred healthcare providers. If you elect private
                        coverage, you should also consider your budget to make
                        sure the plan’s premiums are a good fit.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Medicare resources</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../images/people/cute-couple.jpg"
                  alt="cute elderly couple"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/gep-oep-enrollment-periods/"
                    style={{ color: '#1b0b54' }}
                  >
                    General Enrollment and Open Enrollment Periods
                  </a>
                </Typography>
                <Typography variant="body">
                  Learn about the two Medicare enrollment periods that run from
                  January through March.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/grandparents-on-couch-with-grandkids.jpg"
                  alt="elederly couple sit with grandkids on couch and smile"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/when-is-medicare-advantage-annual-enrollment/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Annual Enrollment Period 101
                  </a>
                </Typography>
                <Typography variant="body">
                  When is the Medicare Annual Enrollment Period? Get answers to
                  this and other AEP-related questions.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/biking-on-beach.jpg"
                  alt="an elderly couple rides bicycles on the beach"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/medicare-enrollment-101/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Enrollment Overview
                  </a>
                </Typography>
                <Typography variant="body">
                  Get fast facts about Medicare Enrollment, including when you
                  qualify and when to enroll.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
